<template>
  <slice-block :slices="data.slices" />
</template>

<script setup>
import {
  LAYOUT_SET_BREADCRUMBS,
  TRACKING_SET_DATA,
} from '~/store/mutationTypes';
import pageHelper from '~/utils/page-helper';
import setUrl from '~/utils/set-url';
import getHead from '~/utils/get-head';
import { getEkomiData } from '~/utils/ekomi';
import {
  routerLinks,
  guideLinks,
  articleLinks,
  productLinks,
  contactLinks,
  marketLinks,
  smartButtonLinks,
  advantageLinks,
  bannerLinks,
  ratingsLinks,
  articlesListMarket,
  questionsAnswersLinks,
  accordionLinks,
  dataVizExampleLinks,
  indexGuideLinks,
} from '~/utils/fetch-links';
import getJSONLD from '~/utils/structured-data';
import { getHomepageLabel } from '~/utils/helpers/data';

const context = useNuxtApp();
const route = useRoute();
const requestEvent = useRequestEvent();
const params = route.params;
const { data } = await useAsyncData('data', async () => {
  try {
    const { $prismic, $store, $i18n } = context;
    const document = await $prismic.client.getByUID(
      'masterpage',
      params.market,
      {
        fetchLinks: [
          ...routerLinks,
          ...guideLinks,
          ...articleLinks,
          ...productLinks,
          ...contactLinks,
          ...marketLinks,
          ...smartButtonLinks,
          ...advantageLinks,
          ...bannerLinks,
          ...ratingsLinks,
          ...articlesListMarket,
          ...questionsAnswersLinks,
          ...accordionLinks,
          ...dataVizExampleLinks,
          ...indexGuideLinks,
        ],
      }
    );

    if (document === undefined) {
      return context.error({ statusCode: 404 });
    }

    const { data } = document;
    const ekomiAccount = data.ekomi_account;
    let reviews;

    if (ekomiAccount) {
      reviews = await getEkomiData(ekomiAccount, 'reviews', context);
    }
    const url = setUrl(context, document, { route, requestEvent });
    const slices = await pageHelper(document, context, undefined, {
      withBreadcrumb: true,
    });

    $store.commit(`tracking/${TRACKING_SET_DATA}`, {
      market: data.title_tracking || data.title || document.uid || null,
      page_type: document.type,
    });

    $store.commit(`layout/${LAYOUT_SET_BREADCRUMBS}`, [
      {
        label: $i18n.t(getHomepageLabel(context, requestEvent)),
        cta: {
          href: '/',
        },
      },
      {
        label: data.breadcrumb_title || $i18n.t('breadcrumbs.default'),
      },
    ]);

    return {
      url,
      page: data,
      slices,
      reviews,
      freespeeParameter: data?.freespee_parameter || null,
    };
  } catch (error) {
    context.error('coucou', error);
  }
});

const scripts = data?.value?.reviews
  ? [
      getJSONLD('market', {
        market: data.value.page,
        url: data.value.url,
        context,
        reviews: data.value.reviews,
      }),
    ]
  : [];

useHead(getHead(context, scripts, data?.value?.freespeeParameter));
</script>
